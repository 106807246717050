"use client"
import { DialogContentContainer } from 'monday-ui-react-core';
import { Heading } from "monday-ui-react-core/next";

export default function DeniedLayout({children}) {

	return (
		<DialogContentContainer
			type={DialogContentContainer.types.POPOVER}
			className='flex flex-col justify-center items-center h-1/2 w-4/5 fixed top-1/2 left-1/2
						-translate-x-1/2 -translate-y-2/3 text-center z-50 rounded'
		>
			There is an error. Please reload the page.
			<Heading type={Heading.types.H3}>{children}</Heading>
		</DialogContentContainer>
	);
}